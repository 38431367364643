export const List = [
  {
    id: 1,
    Teaching:
      "Research Assistant in Advanced Simulation and Computing Lab, Mechanical Engineering Department, IK International University, September 2015 – 2020.",
  },
  {
    id: 2,
    Teaching:
      "Teacher Assistant in Heat transfer, Fluid Mechanics І, CFD, Mechanical Engineering Department, IK International University, 2017 – 2018, Qazvin, Iran.",
  },
  {
    id: 3,
    Teaching:
      "Teacher Assistant in Heat transfer, Fluid Mechanics І, CFD, Mechanical Engineering Department, IK International University, 2017 – 2018, Qazvin, Iran.",
  },
];
